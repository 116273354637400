import { useEffect, useState } from 'react';

function buildLink(link) {
  return (`${process.env.REACT_APP_API_URL}/${link}`);
}

export default function PracticeModal({ isOpen, toggleModal, practice }) {
  const [practiceLink, setPracticeLink] = useState(null);
  useEffect(() => {
    fetch(buildLink(`practices/${practice}`))
      .then((response) => response.json())
      .then((data) => {
        setPracticeLink(data["enrollment_website"]);
      })
      .catch((error) => {
        // TODO: How to properly Sentry log the error if it's on an external site?
      });
  }, [practice]);

  return (<div>
    <div
      className={`flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full
          ${!isOpen && "hidden"}
        `}
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900">
              Join with Taro Health
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              onClick={() => toggleModal()}
            >
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
            <p className="text-base leading-relaxed text-gray-500">
              With Taro, members can get access to a DPC membership at no additional cost using their insurance benefits.
            </p>
            <div className="grid grid-cols-2 divide-x">
              <div className="pr-4">
                  <p className="text-base leading-relaxed text-gray-500">
                    <span className="font-semibold">Already a Taro member? </span>
                    <span>
                      Sign up with us through your Taro plan.
                    </span>
                    <span className="block mt-4">
                      <a
                        className={`text-white text-sm text-center inline-flex items-center font-medium rounded-lg shadow px-4 py-2.5
                            ${practiceLink ? "bg-taropurple-400 hover:bg-taropurple-300" : "bg-gray-500 hover:bg-gray-400 pointer-events-none"}
                          `}
                        href={practiceLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Become a patient
                      </a>
                    </span>
                    {!practiceLink && <span className="block mt-2 text-xs">Practice link not configured correctly.</span>}
                </p>
              </div>
              <div className="pl-4">
                <p className="text-base leading-relaxed text-gray-500">
                  <span className="font-semibold">Not yet a Taro member? </span>
                  <span>
                    See if you qualify for a plan.
                  </span>
                  <span className="block mt-4">
                    <a
                      className="bg-taropurple-400 hover:bg-taropurple-300 text-white text-sm text-center inline-flex items-center font-medium rounded-lg shadow px-4 py-2.5"
                      href="https://tarohealth.healthsherpa.com/?_agent_id=tarohealth"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Taro plans
                      <svg className="fill-white ml-1.5 h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l82.7 0L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3l0 82.7c0 17.7 14.3 32 32 32s32-14.3 32-32l0-160c0-17.7-14.3-32-32-32L320 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z"/></svg>
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className={`bg-gray-900/50 fixed inset-0 z-40
        ${!isOpen && "hidden"}
      `}
    />
  </div>);
}
