import React, { useState } from 'react';
import PracticeModal from './PracticeModal.js';
import logo from './taro-logomark-colorDark.svg';

export default function App({ practice }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return ([
    <button
      key={`${practice}-btn`}
      className="bg-white hover:bg-gray-100 text-gray-800 text-center inline-flex items-center font-medium border border-gray-400 rounded-lg shadow px-4 py-2.5"
      onClick={() => toggleModal()}
    >
      <img src={logo} className="h-4 mr-3" alt="Taro Health" />
      Join with Taro Health
    </button>,
    <PracticeModal
      key={`${practice}-modal`}
      practice={practice}
      isOpen={isOpen}
      toggleModal={() => toggleModal()}
    />
  ]);
}
