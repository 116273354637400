import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

const widgetDivs = document.querySelectorAll('.taro-widget');

// Inject our React App into each class
widgetDivs.forEach(div => {
    const widget = createRoot(div);
    widget.render(
      <App practice={div.dataset.practice} />
    );
});
